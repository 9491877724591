import styled from 'styled-components'
import { Button } from 'components/atoms/Button'
import { GridViewIcon, ViewListIcon } from 'components/atoms/Icons'
import { useWidthBreakpoint } from 'hooks'
import { Tooltip } from 'components/atoms/Tooltip'

interface Props {
  tableView: boolean
  setTableView: (val: boolean) => void
}

export const ViewButton = ({ tableView, setTableView }: Props) => {
  const mobileLayout = useWidthBreakpoint('mobileAndSmaller')
  const iconSize = mobileLayout ? 16 : 24

  return (
    <Tooltip tooltip={`Switch to ${tableView ? 'grid' : 'table'} view`} side="left">
      <SwitchViewButton
        icon={tableView ? <GridViewIcon size={iconSize} /> : <ViewListIcon size={iconSize} />}
        onClick={() => setTableView(!tableView)}
        view={mobileLayout ? 'secondary' : 'link'}
      />
    </Tooltip>
  )
}

const SwitchViewButton = styled(Button)`
  width: 40px;
  min-width: 40px;
  padding: 0;
`
