import { StatusModal } from 'components/organisms/Modals/StatusModal'
import { Text } from 'components/atoms/Text'
import { LinkButton } from 'components/atoms/LinkButton'
import styled from 'styled-components'

export const ExpiredMagicLinkModal = () => {
  return (
    <StatusModal state="Fail">
      <TextWrapper>
        <Text color="extra-light">Your authentication link has expired.</Text>
        <Text color="extra-light">Please sign in again to generate a new one.</Text>
      </TextWrapper>
      <LinkButton view="tertiary" href="/signin">
        Sign in
      </LinkButton>
    </StatusModal>
  )
}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
