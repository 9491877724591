import { PortfolioCard, PortfolioCardGrid } from 'components/Portfolios/PortfolioCard/PortfolioCard'
import { isPotentialOffering, LiveDataOffering, PotentialOffering } from 'types/Portfolio'
import { sortByStartTime } from 'domain/offering'

interface Props {
  portfolios: (LiveDataOffering | PotentialOffering)[]
  className?: string
}

export const PortfolioGrid = ({ portfolios, className }: Props) => {
  const sorted = portfolios.sort(sortByStartTime)

  return (
    <PortfolioCardGrid className={className}>
      {sorted.map((portfolio) => {
        const key = isPotentialOffering(portfolio) ? portfolio.id : portfolio.address
        return <PortfolioCard key={key} portfolio={portfolio} />
      })}
    </PortfolioCardGrid>
  )
}
