import { MetaMaskIcon, WalletConnectIcon } from 'components/atoms/Icons'
import { ConnectWalletButton } from 'components/molecules/ConnectWalletButton'
import { Row } from 'components/atoms/Row'
import styled from 'styled-components'
import { LearnWalletLink } from 'components/molecules/LearnWalletLink'
import { Column } from 'components/atoms/Column'
import { Text } from 'components/atoms/Text'

export const ConnectWalletBillboard = () => {
  return (
    <Wrapper>
      <IconsWrapper>
        <MetaMaskIcon width={53} height={44} />
        <WalletConnectIcon width={53} height={44} />
      </IconsWrapper>
      <Content>
        <Text as="h3" variant="title3" bold color="dark">
          Connect your wallet to take full advantage of the features
        </Text>
        <LearnWalletLink />
      </Content>
      <ConnectWalletButton />
    </Wrapper>
  )
}

const IconsWrapper = styled(Row)`
  gap: 16px;
  padding-right: 40px;
  margin-right: 40px;
  border-right: 1px solid ${({ theme }) => theme.colors.Mouse};
  mix-blend-mode: luminosity;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    border: none;
  }
`

const Content = styled(Column)`
  gap: 8px;

  h3 {
    font-weight: 800;
  }

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    margin-top: 16px;
  }
`

const Wrapper = styled(Row)`
  display: grid;
  grid-template-columns: auto 1fr auto;
  background: #f6f7f6;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.Iron};

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    grid-template-columns: 1fr;
    padding: 40px 26px;
  }

  button {
    background: transparent;
    margin-left: 52px;

    @media ${({ theme }) => theme.queries.tabletAndSmaller} {
      margin: 24px 0 0 0;
    }

    @media ${({ theme }) => theme.queries.largeMobileAndSmaller} {
      display: none;
      margin: 0;
    }
  }
`
