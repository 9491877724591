import { Layout } from 'components/Layout'
import { PortfolioGrid } from 'components/Portfolios/PortfolioCard'
import { DashboardContainer, DashboardHeader } from 'components/Dashboards'
import { useLocalStorage } from 'hooks'
import { LiveDataOffering, PotentialOffering } from 'types/Portfolio'
import { ViewButton } from 'components/Portfolios/ViewButton'
import { AddOfferingButton } from 'components/Portfolios/AddOfferingButton'
import {
  apyColumn,
  managerColumn,
  minCommitmentColumn,
  nameColumn,
  statusColumn,
  termColumn,
  creationColumn,
} from 'components/Portfolios/PortfolioTable/tableColumns'
import { Datatable } from 'components/organisms/Datatable'
import { useEthers } from '@usedapp/core'
import { ConnectWalletBillboard } from 'components/Login/ConnectWalletBillboard'
import { LoggedUserConnectModal } from 'components/Dashboards/OfferingsDashboard/LoggedUserConnectModal'
import { ExpiredMagicLinkModal } from 'components/organisms/Modals/ExpiredMagicLinkModal/ExpiredMagicLinkModal'

interface Props {
  portfolios: (PotentialOffering | LiveDataOffering)[]
  showConnectModal?: boolean
  isMagicLinkExpired: boolean
}

export const OfferingsDashboard = ({ portfolios, showConnectModal, isMagicLinkExpired }: Props) => {
  const { account } = useEthers()
  const [tableView, setTableView] = useLocalStorage<boolean>('table-view', false)

  return (
    <Layout>
      <DashboardContainer>
        <DashboardHeader
          title="Offerings"
          rightSide={
            <>
              <ViewButton tableView={tableView} setTableView={setTableView} />
              <AddOfferingButton />
            </>
          }
        />
        {!account && <ConnectWalletBillboard />}
        {tableView ? (
          <Datatable
            initSortKey="Status"
            initSortOrder="ascending"
            data={portfolios}
            columns={[
              nameColumn,
              managerColumn,
              apyColumn,
              minCommitmentColumn,
              creationColumn,
              termColumn,
              statusColumn,
            ]}
          />
        ) : (
          <PortfolioGrid portfolios={portfolios} />
        )}
      </DashboardContainer>
      {isMagicLinkExpired && <ExpiredMagicLinkModal />}
      <LoggedUserConnectModal showConnectModal={showConnectModal} />
    </Layout>
  )
}
