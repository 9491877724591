import { useEffect, useState } from 'react'
import { AccountModal } from 'components/organisms/Modals'
import { useEthers } from '@usedapp/core'

interface LoggedUserConnectModalProps {
  showConnectModal?: boolean
}

export const LoggedUserConnectModal = ({ showConnectModal }: LoggedUserConnectModalProps) => {
  const { account, isLoading } = useEthers()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!isLoading && !account) {
      setShowModal(!!showConnectModal)
    }
    if (account) {
      setShowModal(false)
    }
  }, [showConnectModal, account, isLoading])

  return <AccountModal open={showModal} setOpen={setShowModal} />
}
