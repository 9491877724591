import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export const useIsMagicLinkExpired = () => {
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const router = useRouter()

  useEffect(() => {
    const params = new URLSearchParams(router.asPath)
    const errorCode = params.get('error_code')
    setIsExpired(!!(errorCode && errorCode === '401'))
  }, [router.asPath])

  return isExpired
}
