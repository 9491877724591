import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { getPortfolios } from 'backend/portfolios'
import { useIsMagicLinkExpired, useLiveDataPortfolios, useNetworkEnvironmentSwitch } from 'hooks'
import { useMemo } from 'react'
import { setDefaultCache } from 'utils'
import { getRuntimeEnvironment } from 'config'
import { getPotentialOfferings } from 'backend/portfolios/getPotentialOfferings'
import { OfferingsDashboard } from 'components/Dashboards/OfferingsDashboard'
import { useRouter } from 'next/router'
import { SerializableOffering, SerializablePotentialOffering } from 'types/Serializable'
import { deserializeOffering, serializeOffering } from 'backend/serialization/Offering'
import { deserializePotentialOffering, serializePotentialOffering } from 'backend/serialization/PotentialOffering'
import { createPagesServerClient } from '@supabase/auth-helpers-nextjs'

type DashboardPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function DashboardPage({ rawPortfolios, rawPotentialOfferings }: DashboardPageProps) {
  const router = useRouter()
  const isExpired = useIsMagicLinkExpired()

  const onChainPortfolios = useMemo(
    () => (rawPortfolios as SerializableOffering[]).map(deserializeOffering),
    [rawPortfolios],
  )
  const offChainPortfolios = useMemo(
    () => (rawPotentialOfferings as SerializablePotentialOffering[]).map(deserializePotentialOffering),
    [rawPotentialOfferings],
  )
  const liveDataPortfolios = useLiveDataPortfolios(onChainPortfolios)
  const allPortfolios = [...offChainPortfolios, ...liveDataPortfolios]

  useNetworkEnvironmentSwitch()

  return (
    <OfferingsDashboard
      portfolios={allPortfolios}
      showConnectModal={!isExpired && !!router.query.connectModal}
      isMagicLinkExpired={isExpired}
    />
  )
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { res, req } = ctx
  setDefaultCache(res)

  const { chainId } = getRuntimeEnvironment(req)
  const supabase = createPagesServerClient(ctx)

  const [potentialOfferings, portfolios] = await Promise.all([
    getPotentialOfferings(chainId, supabase),
    getPortfolios(chainId, supabase),
  ])

  return {
    props: {
      rawPortfolios: portfolios.map(serializeOffering),
      rawPotentialOfferings: potentialOfferings.map(serializePotentialOffering),
    },
  }
}
