import { useEffect, useState } from 'react'
import { isServerSideRender } from 'utils'

export const useLocalStorage = <T>(key: string, initialValue: T, usesServerSideRendering = true) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (isServerSideRender() || usesServerSideRendering) {
      return initialValue
    }

    return getValueFromLocalStorage(key, initialValue)
  })

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      if (!isServerSideRender()) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {}
  }

  useEffect(() => {
    setStoredValue(getValueFromLocalStorage(key, initialValue))
  }, [initialValue, key])

  return [storedValue, setValue] as const
}

const getValueFromLocalStorage = <T>(key: string, initialValue: T): T => {
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    return initialValue
  }
}
