import Cookies from 'universal-cookie'
import { parseNetworkEnvironment } from 'config'
import { useBrowserLayoutEffect } from 'hooks/useBrowserLayoutEffect'

export const useNetworkEnvironmentSwitch = () => {
  useBrowserLayoutEffect(() => {
    const params = new URLSearchParams(document.location.search)

    if (!params.has('devNetwork')) {
      return
    }

    const devNetwork = params.get('devNetwork')

    const cookies = new Cookies()

    // Can be done manually by devs, but this is a convenience, so we can share links
    if (!devNetwork || devNetwork == 'clear') {
      cookies.remove('devNetwork')
    } else {
      const network = parseNetworkEnvironment(devNetwork)
      cookies.set('devNetwork', network)
    }

    window.location.href = window.location.origin
  }, [])
}
