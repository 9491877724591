import { IncomingMessage } from 'http'
import { ChainId } from '@usedapp/core'
import { SupportedChainId } from 'constants/chain'
import { environment } from 'config/environment'

const { isProduction } = environment

const networkEnvironments = ['testnet', 'mainnet'] as const
type NetworkEnvironment = (typeof networkEnvironments)[number]

interface RuntimeEnvironment {
  chainId: SupportedChainId
  network: NetworkEnvironment
}

export function parseNetworkEnvironment(aString: string | null | undefined): NetworkEnvironment {
  if (isNetworkEnvironment(aString)) {
    return aString
  }

  return isProduction ? 'mainnet' : 'testnet'
}

export function getRuntimeEnvironment(
  req: IncomingMessage & {
    cookies: Partial<{ [key: string]: string }>
  },
): RuntimeEnvironment {
  const { devNetwork } = req.cookies
  const network = parseNetworkEnvironment(devNetwork)
  const chainId = getChainIdForEnvironment(network)

  return { network, chainId }
}

function isNetworkEnvironment(aString: unknown): aString is NetworkEnvironment {
  return networkEnvironments.includes(aString as NetworkEnvironment)
}

const chainIdsForNetworks: Record<NetworkEnvironment, SupportedChainId> = {
  mainnet: ChainId.Mainnet,
  testnet: ChainId.Sepolia,
}

function getChainIdForEnvironment(environment: NetworkEnvironment): SupportedChainId {
  return chainIdsForNetworks[environment]
}
